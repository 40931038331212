import { Fragment, useContext, useState } from "react"
import style from "./CalendarBotev.Module.css"
import { January } from "./2025/January.js"
import { February } from "./2025/February"
import { March } from "./2025/March"
import { April } from "./2025/April"
import { May } from "./2025/May"
import { June } from "./2025/June"
import { July } from "./2025/July"
import { August } from "./2025/August"
import { September } from "./2025/September"
import { October } from "./2025/October"
import { November } from "./2025/November"
import { December } from "./2025/December"
import { LangContext } from "../../../contexts/Language.js";

const monthEn = ["January", "February", "March",
    "April", "May", "June", "July", "August", "September",
    "October", "November", "December"];

const monthBg = ['Януари', 'Февруари', 'Март', 'Април', 'Май',
    'Юни', 'Юли', 'Август', 'Септември', 'Октомври',
    'Ноември', 'Декември']


export const CalendarBotev = () => {
    const { lang } = useContext(LangContext);

    let date = new Date();
    let year = date.getFullYear();
    const [curMonth, setCurMonth] = useState(date.getMonth());


    const goPrev = (curMonth) => {
        if (curMonth === 0) {
            setCurMonth(11);
        } else {
            setCurMonth(curMonth - 1);
        }
    };

    const goNext = (curMonth) => {
        if (curMonth === 11) {
            setCurMonth(0);
        } else {
            setCurMonth(curMonth + 1);
        }
    }

    return (
        <section className="calendar">
            <ul className="month">
                <li onClick={() => goPrev(curMonth)} className="prev">&#10094;</li>
                {lang === 'EN' && <li>{monthEn[curMonth]} - {year}</li>}
                {lang === 'BG' && <li>{monthBg[curMonth]} - {year}</li>}              
                <li onClick={() => goNext(curMonth)} className="next">&#10095;</li>
            </ul>
            <ul className="days">
                {lang === 'EN' && <section>
                    <li>Mon</li>
                    <li>Tue</li>
                    <li>Wed</li>
                    <li>Thu</li>
                    <li>Fri</li>
                    <li>Sat</li>
                    <li>Sun</li>
                </section>}
                {lang === 'BG' && <section>
                    <li>Пон</li>
                    <li>Вт</li>
                    <li>Ср</li>
                    <li>Чет</li>
                    <li>Пет</li>
                    <li>Съб</li>
                    <li>Нед</li>
                </section>}

                {curMonth === 0 && <January />}
                {curMonth === 1 && <February />}
                {curMonth === 2 && <March />}
                {curMonth === 3 && <April />}
                {curMonth === 4 && <May />}
                {curMonth === 5 && <June />}
                {curMonth === 6 && <July />}
                {curMonth === 7 && <August />}
                {curMonth === 8 && <September />}
                {curMonth === 9 && <October />}
                {curMonth === 10 && <November />}
                {curMonth === 11 && <December />}
                {lang === 'BG' && <p className="agenda">В червено са отбелязани дните, в които апартамента е зает!</p>}
                {lang === 'EN' && <p className="agenda">The days when the apartment is occupied are marked in red!</p>}
            </ul>
        </section>
    );
};