const daysInWeek = 7;
const weeks = 6;

export function SkipDays(startDay, finishDay, reservations, hasSixthWeek = false) {
    var calendar = [];
    var counter = 1

    for (let week = 1; week <= weeks; week++) {
        var days = [];

        for (let day = 1; day <= daysInWeek; day++) {

            if (week === 6 && !hasSixthWeek) {
                break;
            }

            if (week === 1 && day < startDay 
                || week === 5 && day > finishDay && !hasSixthWeek
                || week === 6 && hasSixthWeek && day > finishDay) {
                days.push(<li></li>)
                continue;
            };

            if (reservations.includes(counter)) {
                days.push(<li className="active">{counter}</li>)
            } else {
                days.push(<li>{counter}</li>)
            };

            counter++;
        }

        calendar.push(<section>{days}</section>)
    }

    return calendar;
}